var site = site || {};
site.account = site.account || {};

/**
  * One-time call to collect specific DOM elements on the page.
  * @methodOf site.account
*/

(function($) {
  site.account.getPassDOMNodes = function($context) {
    site.account.passwordInfo = $('.js-password-field-info', $context);
  },

  site.account.validatePasswordRules = function() {
    site.account.passwordInfo.removeClass('hidden');
    var $input = $(this),
        value = this.value,
        $rulesItems = $input.closest('.form-item').find('.password-field__rules li'),
        rules = {
          'contains_lower_case_chars': /[a-z]/,
          'contains_uppercase_chars': /[A-Z]/,
          'contains_numbers': /[0-9]/,
          'minimum_length': /.{8,}/
        };
    if ($input.closest('.form-item').find('ul.password-field__rules li.special_character').length > 0) {
      rules.contains_special_character = /[^\w\d|]/;
    }
    // mark the input field if it has any value
    this.classList.toggle('hasValue', this.value);
    // iterate all the rules and validate them
    $.each($rulesItems, function(i, elm) {
      var valid;
      var ruleName = $(elm).data('rule');
      if (rules[ruleName]) {
        valid = rules[ruleName].test(value);
        if (ruleName === 'contains_special_character' && valid && (/[|_]/u).test(value)) {
          valid = !valid;
        }
        $(elm).toggleClass('pass', valid);
      }
    });
  },

  site.account.initPasswordValidationTooltip = function($context) {
    site.account.getPassDOMNodes($context);
    var $passwordContainer = $('.js-password-field', $context);
    var $passwordField = $('input[type="text"], input[type="password"]', $passwordContainer);
    var isMobile = Unison.fetch.now().name === 'small';
    var password_tooltip = Drupal.settings.common.password_tooltip || 0;
    if (isMobile && !password_tooltip) {
      site.account.passwordInfo.removeClass('hidden');
    }
    if ($(window).width() === 1024 && $('.js-signin-block', $context).hasClass('signin-overlay')) {
      site.account.passwordInfo.removeClass('hidden');
    }
    $passwordField.on('input focus', site.account.validatePasswordRules);
    $passwordField.on('blur', function() {
      // Adding a small delay to allow the user to click the Register CTA. See NADTX0-1443.
      setTimeout(() => {
        site.account.passwordInfo.addClass('hidden');
        if (isMobile && !password_tooltip) {
          site.account.passwordInfo.removeClass('hidden');
        }
        if ($(window).width() === 1024 && $('.js-signin-block', $context).hasClass('signin-overlay')) {
          site.account.passwordInfo.removeClass('hidden');
        }
      }, 500);
    });
  },

  Drupal.behaviors.validatePassword = {
    attach: function(context) {
      site.account.initPasswordValidationTooltip(context);
    },
  };
})(jQuery);
